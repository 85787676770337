// extracted by mini-css-extract-plugin
var _1 = "#899ebe";
var _2 = "#666771";
var _3 = "#d3d3d3";
var _4 = "#f4516c";
var _5 = "#ebedf2";
var _6 = "#000";
var _7 = "#fff133";
var _8 = "#bac6d9";
var _9 = "#ffb822";
export { _1 as "brandColor", _2 as "customGrey", _3 as "customLightGrey", _4 as "dangerColor", _5 as "inputBorderColor", _6 as "nodeEnergyBlack", _7 as "primaryColor", _8 as "secondaryBrandColor", _9 as "warningColor" }
